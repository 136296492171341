$(function() {
  // パッケージ画像のスライダー
  $('[data-id="itemImages"] .package-images.slick').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  // 商品詳細ページのセット商品アコーディオン
  $('[data-id="itemInformation"] .accordion .accordion-hit-area').click(function(){
    const $this = $(this);
    const target = $(this).data('target');
    const $target = $(target);
    $this.attr('aria-expand', !$target.is(':visible'));
    $target.attr('aria-hidden', $target.is(':visible'));
    $target.slideToggle();
  });

  // レビューに対するフィードバック
  $(document).on('ajax:success', '.item-review .feedback a[data-remote="true"]', function(e) {
    const [response, _status, _xhr] = e.detail;
    Object.keys(response.partials).forEach(function(key) {
      $(`[data-id="${key}"]`).replaceWith(this[key]);
    }, response.partials);
    return false;
  });

  // 未選択のアソート項目がある場合はカートに入れるボタンを無効化
  const $addCartButton = $('button[data-id="addCartButton"]')
  const $selectAssortItems = $('select[data-selector="selectAssortItem"]')
  $selectAssortItems.on('change', function() {
    const len = $selectAssortItems.filter(function(_i, el) {
      const val = $(el).val()
      return !Number.isFinite(parseInt(val))
    }).length
    $addCartButton.prop('disabled', 0 < len)
  }).trigger('change')
});
